import React, {useRef, useState} from "react";
import { Success } from '../success';
import emailjs from "@emailjs/browser"

function Modal ({open ,setOpen}) {
    let regex = /[A-Za-zA-Яа-яЁё]/g
    let reg = /[0-9]/g

    const [success, setSuccess] = React.useState(false)
    const [nameInput, setName] = React.useState('')
    const [phoneInput, setPhone] = React.useState('')
    //const [sending, setSending] = React.useState(true)

    const form = useRef();

    const onClickUnsuccessful = () => {
        setSuccess(false)
        //setSending(false)
    }
    const onClickSuccess = () => {
        setSuccess(true)
        //setSending(false)
    }
    const sendEmail = (e) => {
        e.preventDefault();

        if(nameInput.length < 2 || phoneInput.length < 11 || phoneInput.length > 16) {
            alert("Имя или телефон заполнены неверно. Пожалуйста, введите корректные данные перед отправкой.")
        }
        else {
            emailjs.sendForm('service_7adcrqe', 'template_1223d8j', form.current, '4nPDYan8aiLjBJ8BP')
                .then((result) => {
                    onClickSuccess()
                    setName('')
                    setPhone('')
                }, (error) => {
                    alert(`Ошибка, ${error.text}. Обратитесь к представителям организации и мы обязательно решим эту проблему.`)
                });
        }
    }

    return (
         <div className={`overlay animated ${open ? 'show' : ' '}`}>
             {
                 success ? (
                     <div className="modal">
                        <Success onClickSuccess={onClickUnsuccessful} />
                     </div>
                 ) : (
                     <div className="modal">
                         <svg onClick={() => setOpen(false)} height="200" viewBox="0 0 200 200" width="200">
                             <title/>
                             <path
                                 d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z"/>
                         </svg>
                         <form ref={form} onSubmit={sendEmail}>
                             <h2>Записаться на бесплатный урок</h2>
                             <input type="text" name="user_name"  onInput={(e) => {e.target.value = e.target.value.replace(reg, '')}} value={nameInput} onChange={(e) => {setName(e.target.value)}} placeholder='Имя'/>
                             <input mask='+0(000) 00-00-00' name="message" onInput={(e) => {e.target.value = e.target.value.replace(regex, '')}} value={phoneInput} onChange={(e) => {setPhone(e.target.value)}} type="phone" placeholder='+7(978) 000-00-00'/>
                             <input name="submitButton" type='submit' value='Записаться' className="button" onSubmit={(e) => {}}/>
                         </form>
                         <p>*номер телефона должен содержать в себе не менее 11 символов и не более 16.</p>
                     </div>
                 )
             }
         </div>
     )
 }

export default Modal