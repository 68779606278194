import React from "react";
import '../css/header.scss'
import { Link } from 'react-router-dom';


function menuToggle() {
    const navContainer = document.querySelector(".nav-container")
    navContainer.classList.toggle("active")
}

function Header() {
    return (
        <div className="nav-container" onClick={menuToggle}>
            <nav>
                <ul className="mobile-nav">
                    <li>
                        <div className="menu-icon-container">
                            <div className="menu-icon">
                                <span className="line-1"></span>
                                <span className="line-2"></span>
                            </div>
                        </div>
                    </li>
                </ul>

                <ul className="desktop-nav">
                    <li><Link to="/" className="headerLogo"> </Link></li>
                    <li><Link to="/">Главная</Link></li>
                    <li><Link to="/documents">Документы</Link></li>
                    <li><Link to="/gallery">Галерея</Link></li>
                    <li><Link to="/contacts">Контакты</Link></li>
                </ul>
            </nav>
        </div>
    );
}

export default Header;