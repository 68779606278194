import Header from './page/header'
import Main_Page from "./page/main_page";
import Choose from './page/choose'
import Achieve from "./page/student_achieve";
import Directions from "./page/directions";
import CreativeProcess from "./page/creative_process";
import Presents from './page/presents'
import Creator from './page/creator'
import Target from './page/target'
import Team from './page/team'
import Works from './page/works_of_our_students'
import Footer from './page/footer'
import Contact from './page/contacts'

import Docs from './page/docs'

import GalleryPage from "./page/gallery";

import {
    Outlet,
    Route,
    Routes
} from 'react-router-dom';

function Main() {
    return(
        <main>
            <Main_Page></Main_Page>
            <Directions></Directions>
            <Achieve></Achieve>
            <Choose></Choose>
            <CreativeProcess></CreativeProcess>
            <Presents></Presents>
            <Creator></Creator>
            <Target></Target>
            <Team></Team>
            <Works></Works>
        </main>
    );
}

function Documents() {
    return(
        <main>
            <Docs></Docs>
        </main>
    )
}

function Gallery() {
    return(
        <main>
            <GalleryPage></GalleryPage>
        </main>
    )
}

function Contacts() {
    return(
        <main>
            <Contact></Contact>
        </main>
    )
}

function Template() {
    return(
        <div>
            <Header/>
            <Outlet/>
            <Footer></Footer>
        </div>
    );
}

export default function App() {
  return (
      <Routes>
          <Route path='/' element={<Template/>}>
              <Route path='/' element={<Main/>}/>
              <Route path='/documents' element={<Documents/>}/>
              <Route path='/gallery' element={<Gallery/>}/>
              <Route path='/contacts' element={<Contacts/>}/>
          </Route>
      </Routes>
  );
}
