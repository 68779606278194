import React from "react";
import '../css/choose.scss'

import time from '../assets/icons/time.png'
import calendar from '../assets/icons/calendar.png'
import heart from '../assets/icons/heart.png'
import kids from '../assets/icons/kids.png'
import lecturing from '../assets/icons/lecturing.png'
import pay from '../assets/icons/pay.png'

const Card = ({image, description}) => (
    <div className='card'>
        <img src={image} alt="error"/>
        <p>{description}</p>
    </div>
)

function Choose() {
    return(
        <div className='wrap'>
            <div className="choose_container">
                <h1>Почему стоит выбрать нас?</h1>
                <div className="card_container">
                    <Card image={kids} description={'Групповые и индивидуальные задания'}></Card>
                    <Card image={calendar} description={'Начать обучение можно в любой момент'}></Card>
                    <Card image={heart} description={'Все материалы предоставляет школа'}></Card>
                    <Card image={time} description={'Вы сами выбираете удобный график посещения'}></Card>
                    <Card image={lecturing} description={'Возможность выбрать преподавателя'}></Card>
                    <Card image={pay} description={'Доступная стоимость обучения'}></Card>
                </div>
            </div>
        </div>
    )
}

export default Choose;