import React from 'react'
import '../css/gallery.scss'
import img1 from '../assets/gallery/1.png'
import img2 from '../assets/gallery/2.png'
import img3 from '../assets/gallery/3.png'
import img4 from '../assets/gallery/4.png'
import img5 from '../assets/gallery/5.png'
import img6 from '../assets/gallery/6.png'
import img7 from '../assets/gallery/7.png'
import img8 from '../assets/gallery/8.png'
import img9 from '../assets/gallery/9.png'
import img10 from '../assets/gallery/10.png'
import img11 from '../assets/gallery/11.png'
import img12 from '../assets/gallery/12.png'
import img13 from '../assets/gallery/13.jpg'
import img14 from '../assets/gallery/14.jpg'
import img15 from '../assets/gallery/15.jpg'
import img16 from '../assets/gallery/16.jpg'
import img17 from '../assets/gallery/17.jpg'
import img18 from '../assets/gallery/18.jpg'
import img19 from '../assets/gallery/19.jpg'
import img20 from '../assets/gallery/20.jpg'
import img21 from '../assets/gallery/21.jpg'
import img22 from '../assets/gallery/22.jpg'
import img23 from '../assets/gallery/23.jpg'
import img24 from '../assets/gallery/24.jpg'
import img25 from '../assets/gallery/25.jpg'
import img26 from '../assets/gallery/26.jpg'
import img27 from '../assets/gallery/27.jpg'
import img28 from '../assets/gallery/28.jpg'
import img29 from '../assets/gallery/29.jpg'
import img30 from '../assets/gallery/30.jpg'
import img31 from '../assets/gallery/31.jpg'
import img32 from '../assets/gallery/32.jpg'
import img33 from '../assets/gallery/33.jpg'
import img34 from '../assets/gallery/34.jpg'
import img35 from '../assets/gallery/35.jpg'
import img36 from '../assets/gallery/36.jpg'
import img37 from '../assets/gallery/37.jpg'
import img38 from '../assets/gallery/38.jpg'
import img39 from '../assets/gallery/39.jpg'
import img40 from '../assets/gallery/49.jpg'
import img41 from '../assets/gallery/41.jpg'
import img42 from '../assets/gallery/42.jpg'
import img43 from '../assets/gallery/43.jpg'
import img44 from '../assets/gallery/44.jpg'
import img45 from '../assets/gallery/45.jpg'
import img46 from '../assets/gallery/46.jpg'
import img47 from '../assets/gallery/47.jpg'
import img48 from '../assets/gallery/48.jpg'
import img49 from '../assets/gallery/49.jpg'
import img50 from '../assets/gallery/50.jpg'
import img51 from '../assets/gallery/51.jpg'
import img52 from '../assets/gallery/52.jpg'
import img53 from '../assets/gallery/53.jpg'
import img54 from '../assets/gallery/54.jpg'
import img55 from '../assets/gallery/55.jpg'
import img56 from '../assets/gallery/56.jpg'
import img57 from '../assets/gallery/57.jpg'
import img58 from '../assets/gallery/58.jpg'
import img59 from '../assets/gallery/59.jpg'
import img60 from '../assets/gallery/60.jpg'
import img61 from '../assets/gallery/61.jpg'
import img62 from '../assets/gallery/62.jpg'
import img63 from '../assets/gallery/63.jpg'
import img64 from '../assets/gallery/64.jpg'
import img65 from '../assets/gallery/65.jpg'
import img66 from '../assets/gallery/66.jpg'
import img67 from '../assets/gallery/67.jpg'
import img68 from '../assets/gallery/68.jpg'
import img69 from '../assets/gallery/69.jpg'
import img70 from '../assets/gallery/70.jpg'
import img71 from '../assets/gallery/71.jpg'
import img72 from '../assets/gallery/72.PNG'
import img73 from '../assets/gallery/73.PNG'
import img74 from '../assets/gallery/74.PNG'
import img75 from '../assets/gallery/75.PNG'
import img76 from '../assets/gallery/76.JPG'
import img77 from '../assets/gallery/77.JPG'
import img78 from '../assets/gallery/78.JPG'
import img79 from '../assets/gallery/79.JPG'
import img80 from '../assets/gallery/80.JPG'
import img81 from '../assets/gallery/81.JPG'
import img82 from '../assets/gallery/82.JPG'
import img83 from '../assets/gallery/83.JPG'
import img84 from '../assets/gallery/84.JPG'
import img85 from '../assets/gallery/85.JPG'
import img86 from '../assets/gallery/86.JPG'
import img87 from '../assets/gallery/87.JPG'


const images = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14,
    img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28,
    img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, img40, img41, img42,
    img43, img44, img45, img46, img47, img48, img49, img50, img51, img52, img53, img54, img55, img56,
    img57, img58, img59, img60, img61, img62, img63, img64, img65, img66, img67, img68, img69, img70,
    img71, img72, img73, img74, img75, img76, img77, img78, img79, img80, img81, img82, img83, img84,
    img85, img86, img87
]

function Gallery() {
    return(
        <div className='wrap'>
            <div className='gallery_page'>
                {
                    images.map((img) => (
                        <a href={img}>
                            <img src={img} alt="something wrong :("/>
                        </a>))
                }
            </div>
        </div>
    )
}

export default Gallery