import React from 'react'
import '../css/docs.scss'

import passport from '../assets/documents/pasportych.pdf'
import license from '../assets/documents/lic-1.pdf'
import IP from '../assets/documents/IP-1.pdf'
import trud from '../assets/documents/pravila-trud.pdf'
import kompleks from '../assets/documents/kompleks_prog.pdf'
import attestacia2 from '../assets/documents/promezh_attest.pdf'
import attestacia from '../assets/documents/promezh_attest-2.pdf'
import personal from '../assets/documents/lichn-delo.pdf'
import vidach from '../assets/documents/vidach-doc.pdf'
import oznak from '../assets/documents/oznak-rod.pdf'
import otchis from '../assets/documents/zachis-perevod-otchis.pdf'
import rules_in from '../assets/documents/pravila_vnutr.pdf'
import prikaz1 from '../assets/documents/merged-pdf.io_1.pdf'
import prikaz2 from '../assets/documents/merged-pdf.io-2.pdf'
import prikaz3 from '../assets/documents/merged-pdf.io-3.pdf'
import prikaz4 from '../assets/documents/merged-pdf.io-4.pdf'
import prikaz5 from '../assets/documents/merged-pdf.io-5.pdf'
import prikaz6 from '../assets/documents/merged-pdf.io-6.pdf'
import prikaz7 from '../assets/documents/merged-pdf.io-7.pdf'
import prikaz8 from '../assets/documents/merged-pdf.io-8.pdf'
import prikaz9 from '../assets/documents/merged-pdf.io-9.pdf'
import prikaz10 from '../assets/documents/merged-pdf.io-10.pdf'
import prikaz11 from '../assets/documents/merged-pdf.io-11.pdf'
import prikaz12 from '../assets/documents/merged-pdf.io-12.pdf'
import prikaz13 from '../assets/documents/merged-pdf.io-13.pdf'
import prikaz14 from '../assets/documents/merged-pdf.io-14.pdf'
import prikaz15 from '../assets/documents/merged-pdf.io-15.pdf'
import prikaz16 from '../assets/documents/merged-pdf.io-16.pdf'
import prikaz17 from '../assets/documents/merged-pdf.io-17.pdf'
import prikaz18 from '../assets/documents/merged-pdf.io-18.pdf'
import prikaz19 from '../assets/documents/merged-pdf.io-19.pdf'
import prikaz20 from '../assets/documents/merged-pdf.io-20.pdf'
import grafik from '../assets/documents/grafic-obraz.pdf'
import plan from '../assets/documents/ycheb.plan_.pdf'
import material from '../assets/documents/mater.teh_.pdf'
import pay from '../assets/documents/jpg2pdf-2.pdf'
import prikaz22 from '../assets/documents/merged-pdf.io-22.pdf'
import prikaz23 from '../assets/documents/merged-pdf.io-23.pdf'
import prikaz24 from '../assets/documents/merged-pdf.io-24.pdf'

function Documents() {
    return(
        <div className="wrap">
            <div className='documents_page'>
                <div className='block'>
                    <h1>Сведения об образовательной организации</h1>
                    <p>Раздел оформлен в соответствии с требованиями Приказа Минобрнауки РФ от 29.05.2014 № 785 «Об утверждении требований к структуре официального сайта образовательной организации в информационно-телекоммуникационной сети «Интернет» и формату представления на нем информации»</p>
                </div>
                <div className='block'>
                    <h2>Основные сведения об организации</h2>
                    <a href={passport} target='_blank' rel="noreferrer">Паспорт учреждения</a>
                    <a href={license} target='_blank' rel="noreferrer">Лицензия</a>
                    <a href={IP} target='_blank' rel="noreferrer">Свидетельство о регистрации ИП</a>
                </div>
                <div className='block'>
                    <h2>Структура и органы управления</h2>
                    <a href={trud} target='_blank' rel="noreferrer">Правила внутреннего трудового распорядка</a>
                </div>
                <div className='block'>
                    <h2>Документы</h2>
                    <a href={trud} target='_blank' rel="noreferrer">Правила внутреннего трудового распорядка</a>
                    <a href={kompleks} target='_blank' rel="noreferrer">Положение (комплекс программ)</a>
                    <a href={attestacia2} target='_blank' rel="noreferrer">Положение о порядке и формах проведения промежуточной и итоговой аттестации обучающихся по дополнительным общеобразовательным общеразвивающим программам</a>
                    <a href={attestacia} target='_blank' rel="noreferrer">Положение по проведению промежуточной и итоговой аттестации обучающихся, освоивших дополнительную предпрофессиональную общеобразовательную программу в ИП Третьякова Т.С.</a>
                    <a href={personal} target='_blank' rel="noreferrer">Положение о формировании, ведении, хранении и проверки личных дел обучающихся</a>
                    <a href={vidach} target='_blank' rel="noreferrer">Положение о порядке выдачи документа об обучении, лицам, освоившим дополнительные общеобразовательные программы предоставляемые ИП Третьякова Т.С.</a>
                    <a href={otchis} target='_blank' rel="noreferrer">Положение о порядке зачисления, перевода, отчисления и восстановления, учащихся в ИП Третьякова</a>
                    <a href={oznak} target='_blank' rel="noreferrer">Порядок ознакомления родителей (законных представителей) с документами ИП Третьякова Т.С.</a>
                    <a href={rules_in} target='_blank' rel="noreferrer">Правила внутреннего распорядка для учащихся в ИП Третьякова Т.С.</a>
                    <a href={prikaz1} target='_blank' rel="noreferrer">Приказ №1 об утверждении локальных нормативных актов</a>
                    <a href={prikaz2} target='_blank' rel="noreferrer">Приказ №2 о назначении ответственного за ведение, хранение, учёт и выдачу трудовых книжек</a>
                    <a href={prikaz3} target='_blank' rel="noreferrer">Приказ №3 о назначении ответственного за ведение, хранение журналов</a>
                    <a href={prikaz4} target='_blank' rel="noreferrer">Приказ №4 об утверждении формы трудового договора</a>
                    <a href={prikaz5} target='_blank' rel="noreferrer">Приказ №5 об утверждении штатного расписания</a>
                    <a href={prikaz6} target='_blank' rel="noreferrer">Приказ №6 о назначении ответственного за проведение инструктажей по охране труда и технике безопасности</a>
                    <a href={prikaz7} target='_blank' rel="noreferrer">Приказ №7 об утверждении локальных нормативных актов</a>
                    <a href={prikaz8} target='_blank' rel="noreferrer">Приказ №8 об утверждении локальных нормативных актов</a>
                    <a href={prikaz9} target='_blank' rel="noreferrer">Приказ №9 об утверждении локальных нормативных актов</a>
                    <a href={prikaz10} target='_blank' rel="noreferrer">Приказ №10 об утверждении локальных нормативных актов</a>
                    <a href={prikaz11} target='_blank' rel="noreferrer">Приказ №11 об утверждении локальных нормативных актов</a>
                    <a href={prikaz12} target='_blank' rel="noreferrer">Приказ №12 об утверждении локальных нормативных актов</a>
                    <a href={prikaz13} target='_blank' rel="noreferrer">Приказ №13 об утверждении локальных нормативных актов</a>
                    <a href={prikaz14} target='_blank' rel="noreferrer">Приказ №14 об утверждении локальных нормативных актов</a>
                    <a href={prikaz15} target='_blank' rel="noreferrer">Приказ №15 об утверждении локальных нормативных актов</a>
                    <a href={prikaz16} target='_blank' rel="noreferrer">Приказ №16 об утверждении локальных нормативных актов</a>
                    <a href={prikaz17} target='_blank' rel="noreferrer">Приказ №17 об утверждении локальных нормативных актов</a>
                    <a href={prikaz18} target='_blank' rel="noreferrer">Приказ №18 об утверждении локальных нормативных актов</a>
                    <a href={prikaz19} target='_blank' rel="noreferrer">Приказ №19 назначении ответственного за ведение и хранение журналов и книг</a>
                    <a href={prikaz20} target='_blank' rel="noreferrer">Приказ №20 об утверждении локальных нормативных актов</a>
                </div>
                <div className='block'>
                    <h2>Образование</h2>
                    <a href={grafik} target='_blank' rel="noreferrer">График образовательных процессов</a>
                    <a href={plan} target='_blank' rel="noreferrer">Учебный план</a>
                </div>
                <div className='block'>
                    <h2>Материально-техническое обеспечение и оснащение образовательного процесса</h2>
                    <a href={material} target='_blank' rel="noreferrer">Материально-техническая база</a>
                </div>
                <div className='block'>
                    <h2>Платные образовательные услуги</h2>
                    <a href={pay} target='_blank' rel="noreferrer">Положение о платных услугах</a>
                    <a href={prikaz22} target='_blank' rel="noreferrer">Приказ об утверждении нормативных актов по предоставлению платных образовательных услуг</a>
                    <a href={prikaz23} target='_blank' rel="noreferrer">Перечень документов необходимый для получения платной образовательной услуги</a>
                    <a href={prikaz24} target='_blank' rel="noreferrer">Перечень платных услуг</a>
                </div>
                <div className='block'>
                    <h2>Стипендии и иные виды материальной поддержки</h2>
                    <p>Стипендии и иные виды материальной моддержки школа не осуществляет.</p>
                </div>
            </div>
        </div>
    )
}

export default Documents