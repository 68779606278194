import React from "react";
import '../css/creative_process.scss'
import '../index.scss'

import image1 from '../assets/works_page/1.jpg'
import image2 from '../assets/works_page/2.jpg'
import image3 from '../assets/works_page/3.jpg'
import image4 from '../assets/works_page/4.jpg'
import image5 from '../assets/works_page/5.jpg'
import image6 from '../assets/works_page/6.jpg'

import {Link} from "react-router-dom";

function Works() {
    return(
        <div className='wrap'>
            <div className="creative_page">
                <h1>Работы наших учеников</h1>
                <div className="image-box">
                    <img src={image1} alt="Something went wrong :("/>
                    <img src={image2} alt="Something went wrong :("/>
                    <img src={image3} alt="Something went wrong :("/>
                    <img src={image4} alt="Something went wrong :("/>
                    <img src={image5} alt="Something went wrong :("/>
                    <img src={image6} alt="Something went wrong :("/>
                </div>
                <p className="button_1"><Link to='/gallery' className='button_1_text'>Увидеть больше фото</Link></p>
            </div>
        </div>
    );
}

export default Works