import React from "react";
import '../css/main_page.scss'
import '../css/modal.scss'
import photo from '../assets/main_page/main-page-image.png'
import Modal from "./modal";

function Main_Page() {
    const [open, setOpen] = React.useState(false)
    return (
        <div className='wrap'>
            <div className='main_page' id='main'>
                <div>
                    <h1>Начни творить с нами в художественной школе «Карандаш»</h1>
                    <p>Системное обучение живописи, рисунку, графике, композиции, черчению и лепке.</p>
                    <p className='button' onClick={() => setOpen(true)}>Запишись на бесплатное занятие</p>
                </div>
                <div>
                    <img src={photo} alt="failed to get image"/>
                </div>
            </div>
            <Modal open={open} setOpen={setOpen}></Modal>
        </div>
    );
}

export default Main_Page;