import React from 'react';
import success from '../src/assets/success.svg'

export const Success = ({ onClickSuccess }) => {
    return (
        <div className="success-block">
            <img src={success} alt="Success" />
            <h3>Успешно!</h3>
            <p>Спасибо, что выбрали нас! Мы успешно получили ваши данные. Ожидайте звонка от нас :)</p>
            <button onClick={onClickSuccess} className="button">Назад</button>
        </div>
    );
};