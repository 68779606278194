import React from 'react'

import '../css/presents.scss'

const Card = ({ name, description}) => (
    <div className='card'>
        <h2>{name}</h2>
        <p>{description}</p>
    </div>
)

function Presents() {
    return (
        <div className='wrap'>
            <div className="presents_page">
                <h1>Бонусы и подарки!</h1>
                <div className="presents_container">
                    <Card name='«Мамочки творческих детей»' description='Приглашаем мамочек на уроки живописи и рисунка со скидкой 50%!'></Card>
                    <Card name='«Приведи друга»' description='Приведи друга и получи урок по рисунку или лепке в подарок!'></Card>
                </div>
            </div>
        </div>
    );
}

export default Presents