import React from "react";
import '../css/target.scss'
import photo from '../assets/target_page/target.png'
import asterisk from '../assets/icons/asterisk.png'
import depth from '../assets/icons/depth.png'
import Modal from './modal'

function Target() {
    const [open, setOpen] = React.useState(false)
    return(
        <div className='container'>
            <div className="wrap">
                <div className="target_page">
                    <div>
                        <h1>Главные цели школы</h1>
                        <div>
                            <img src={asterisk} alt=""/>
                            <p>С «нуля» вывести учащегося на высокий уровень его художественно - эстетического развития.</p>
                        </div>
                        <div>
                            <img src={depth} alt=""/>
                            <p>Подготовить к сдаче творческих экзаменов в профильный ВУЗ.</p>
                        </div>
                        <a className='button' onClick={() => setOpen(true)} href="#main">Записаться на бесплатное занятие</a>
                    </div>
                    <div className='target_image'>
                        <img src={photo} alt="Failed to get image"/>
                    </div>
                </div>
                <Modal open={open} setOpen={setOpen}></Modal>
            </div>
        </div>
    )
}

export default Target;