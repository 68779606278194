import React from 'react'
import photo from '../assets/school.png'
import '../css/contacts.scss'
import vk from "../assets/icons/vk.png";


function Contacts() {
    return(
        <div className="wrap">
            <div className="contacts_page">
                <h1>Адрес школы</h1>
                <div className="contacts_container">
                    <div>
                        <h1>Симферополь</h1>
                        <p>Республика Крым, г. Симферополь, пр. Победы 41, второй этаж</p>
                        <p>Телефон: +7(978)-088-61-64</p>
                        <p>Время работы:</p>
                        <p>Пн-Сб: 10:00 - 19:00</p>
                        <p>Воскресенье выходной</p>
                    </div>
                    <img src={photo} alt="something went wrong :("/>
                </div>
                <h1>Посмотрите на карте</h1>
                <div className="map">
                    <a target='_blank'  rel="noreferrer" href="https://yandex.ru/maps/146/simferopol/?utm_medium=mapframe&utm_source=maps">Симферополь</a>
                    <a target='_blank'  rel="noreferrer" href="https://yandex.ru/maps/146/simferopol/house/prospekt_pobedy_41/Z00YdwZnQU0OQFpufXV3cHxkYw==/?ll=34.118757%2C44.960969&utm_medium=mapframe&utm_source=maps&z=15.65">Проспект Победы, 41 — Яндекс Карты</a>
                    <iframe
                        src="https://yandex.ru/map-widget/v1/?ll=34.118757%2C44.960969&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNDU0ODI3MjgzEmbQoNC-0YHRgdC40Y8sINCg0LXRgdC_0YPQsdC70LjQutCwINCa0YDRi9C8LCDQodC40LzRhNC10YDQvtC_0L7Qu9GMLCDQv9GA0L7RgdC_0LXQutGCINCf0L7QsdC10LTRiywgNDEiCg23eAhCFSXYM0I%2C&z=15.65"
                        width="80%" height="350" allowFullScreen="true"
                        title='map'></iframe>
                </div>
            </div>
        </div>
    )
}

export default Contacts