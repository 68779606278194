import React from "react";
import '../css/student_achieve.scss'
import photo from '../assets/achieve_page/achieve.jpg'

function Achieve() {
    return(
        <div className='container'>
            <div className="wrap">
                <div className="achieve_page">
                    <div>
                        <h1>Наши достижения</h1>
                        <p>Подготовили к вступительным экзаменам в художественные и архитектурно-строительные ВУЗы более 100 учеников.</p>
                        <p>Наша ученица, Саша Панфилова, одержала победу в конкурсе «Большая перемена» и получила премию 1.000.00 рублей. Наставник творческого проекта - Гук Владимир Александрович.</p>
                    </div>
                    <div>
                        <img src={photo} alt="Failed to get image"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Achieve;