import React from "react";
import '../css/directions.scss'

import img1 from '../assets/directions_page/painting.png'
import img2 from '../assets/directions_page/lepka.png'
import img3 from '../assets/directions_page/VNZ.png'
import img4 from '../assets/directions_page/cherchenie.png'

import img5 from '../assets/directions_page/jivopis.png'
import img6 from '../assets/directions_page/picture.png'
import img7 from '../assets/directions_page/grafic.png'
import img8 from '../assets/directions_page/compisition.png'

import img9 from '../assets/directions_page/adult.png'
import img10 from '../assets/directions_page/corporate.png'
import img11 from '../assets/directions_page/birthday.png'
import img12 from '../assets/directions_page/certificate.png'

const Card = ({image, name, age}) => (
    <div className='card'>
        <img src={image} alt="error"/>
        <h2>{name}</h2>
        <p>{age}</p>
    </div>
)

function Directions() {
    return(
        <div className="wrap">
            <div className='directions_container'>
                <h1>Основные направления</h1>
                <div className="directions_cards_container">
                    <Card image={img1} name={'Основы рисования'} age={'5-12 лет'} ></Card>
                    <Card image={img2} name={'Лепка'} age={'5-12 лет'} ></Card>
                    <Card image={img3} name={'Подготовка к поступлению в ВУЗы'} ></Card>
                    <Card image={img4} name={'Черчение'} age={'13-18 лет'} ></Card>

                    <Card image={img5} name={'Живопись'} age={'13-18 лет'} ></Card>
                    <Card image={img6} name={'Рисунок'} age={'13-18 лет'} ></Card>
                    <Card image={img7} name={'Графика'} age={'13-18 лет'} ></Card>
                    <Card image={img8} name={'Композиция'} age={'13-18 лет'}></Card>

                    <Card image={img9} name={'Курсы для взрослых'} ></Card>
                    <Card image={img10} name={'Творческий корпоратив'}></Card>
                    <Card image={img11} name={'Творческий день рождения'} ></Card>
                    <Card image={img12} name={'Подарочный сертификат'} ></Card>
                </div>
            </div>
        </div>
    )
}

export default Directions