import React from 'react'
import '../css/team.scss'

import photo1 from '../assets/team_page/vladimir.png'
import photo2 from '../assets/team_page/natalia.png'
import photo3 from '../assets/team_page/tatyana.png'
import photo4 from '../assets/team_page/ludmila.png'
import photo5 from '../assets/team_page/narine.png'

const Card = ({image, children}) => (
    <div className='team_card'>
        <img src={image} alt="error"/>
        { children }
    </div>
)

function Team() {
    return(
        <div className='wrap'>
            <div className="team_page">
                <h1>Наша команда</h1>
                <Card image={photo1}>
                    <div>
                        <h2>Гук Владимир Александрович</h2>
                        <p>Опыт работы преподавателем: 25 лет</p>
                        <p>Художник-оформитель, педагог</p>
                        <p>Магистр изобразительного искусства</p>
                        <p>Действующий художник</p>
                        <p>Участник международных выставок</p>
                        <p>«Крымское художественное училище им. Самокиша» 1982 г.</p>
                        <p>«Южноукраинский национальный педагогический университет им. Ушинского» 2015г.</p>
                    </div>
                </Card>
                <Card image={photo2}>
                    <div>
                        <h2>Буданова Наталья Геннадьевна</h2>
                        <p>Опыт работы преподавателем: 15 лет</p>
                        <p>Педагог по изобразительному искусству</p>
                        <p>«Уральская государственная архитектурно-художественная академия» г. Екатеринбург.</p>
                    </div>
                </Card>
                <Card image={photo3}>
                    <div>
                        <h2>Третьякова Татьяна Сергеевна</h2>
                        <p>Опыт работы преподавателем: 7 лет</p>
                        <p>Педагог по изобразительному искусству</p>
                        <p>Национальная Академия природоохранного и курортного строительства (Архитектор)</p>
                    </div>
                </Card>
                <Card image={photo4}>
                    <div>
                        <h2>Глухий Людмила Владимировна</h2>
                        <p>Опыт работы преподавателем: 30 лет</p>
                        <p>Инженер-строитель</p>
                        <p>Старший преподаватель кафедры геометрического и компьютерного моделирования энергоэффективных зданий Академии строительства и архитектуры</p>
                        <p>«Севастопольский приборостроительный институт» 1977г.</p>
                    </div>
                </Card>
                <Card image={photo5}>
                    <div>
                        <h2>Аствацатурова Нарине Самвеловна</h2>
                        <p>Опыт работы преподавателем: 3 года</p>
                        <p>Педагог по изобразительному искусству</p>
                        <p>«Крымский Федеральный Университет им. Вернадского» г. Симферополь.</p>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Team