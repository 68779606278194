import React from 'react'

import logo from '../assets/logo.png'
import vk from '../assets/icons/vk.png'
import '../css/footer.scss'
import Modal from "./modal";
import { Link } from "react-router-dom";

function Footer() {
    const [open, setOpen] = React.useState(false)

    return(
        <div className='container'>
            <div className='wrap'>
                <div className='footer_page'>
                    <div>
                        <img src={logo} alt="something went wrong :("/>
                        <p>Юные художники Крыма, удачного Вам старта! Не упустите свою возможность! С любовью и заботой, Ваша Татьяна Третьякова.</p>
                        <a className='button' onClick={() => setOpen(true)} href="#root">Записаться на бесплатное занятие</a>
                    </div>
                    <div>
                        <p><Link className='footer_link' to='/'>Главная</Link></p>
                        <p><Link className='footer_link' to='/documents'>Документация</Link></p>
                        <p><Link className='footer_link' to='/gallery'>Галерея</Link></p>
                        <p><Link className='footer_link' to='/contacts'>Контакты</Link></p>
                    </div>
                    <div>
                        <p>Контакты:</p>
                        <p>Адрес: г. Симферополь, пр-т Победы, 41</p>
                        <p>Телефон: +7(978)-088-61-64</p>
                        <p>E-mail: tretyakova_2065@mail.ru</p>
                        <p>Подписывайтесь на нас в наших социальных сетях!</p>
                        <a href="https://vk.com/shkola_karandash" target="_blank"><img src={vk} alt="vk_icon error"/></a>
                    </div>
                </div>
                <span className='separator'></span>
                <p className='copyright'>Лицензия на осуществление образовательной деятельности № 0847 от 21.11.2016</p>
            </div>
            <Modal open={open} setOpen={setOpen}></Modal>
        </div>
    )
}

export default Footer