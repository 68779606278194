import React from 'react'
import '../css/creator.scss'

import photo from '../assets/creator_page/creator.png'

function Creator() {
    return(
        <div className='wrap'>
            <div className="creator_page">
                <h1>От создательницы школы</h1>
                <div className="creator_container">
                    <img src={photo} alt="failed to get image"/>
                    <div>
                        <h2>Здравствуйте! Меня зовут Татьяна Третьякова.</h2>
                        <p>Я учредитель и руководитель Художественной школы «КАРАНДАШ».</p>
                        <p>Благодаря нашей художественной школе у творческих деток появилась великолепная возможность развивать свои способности и оттачивать художественные навыки.</p>
                        <p>Авторские образовательные программы, качественные материалы, уютный интерьер, творческая атмосфера, - всё это создано для полноценного образовательного процесса в области изобразительного искусства.</p>
                        <p>Главным достоинством школы является преподавательский состав и методика преподавания.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Creator